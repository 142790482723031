import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth.service";
import FadeIn from "react-fade-in";
import DatePicker from "react-datepicker";
import TaskService from "../services/task.service";
import FamilyService from "../services/family.service";
import "react-datepicker/dist/react-datepicker.css";
import Avatar from "react-avatar";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                Title cannot be blank!
            </div>
        );
    }
};

const titleLength = (value) => {
    if (value.length > 40) {
        return (
            <div className="alert alert-danger" role="alert">
                Title cannot exceed 40 characters!
            </div>
        );
    }
};

const Register = () => {
    let navigate = useNavigate();
    const form = useRef();
    const checkBtn = useRef();
    const currentUser = AuthService.getCurrentUser();
    const initialTaskState = {
        taskId: null,
        familyId: "",
        userId: "",
        ownerId: "",
        title: "",
        description: "",
        completed: false,
        dueDate: null
    };
    const [task, setTask] = useState(initialTaskState);
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");
    const [members, setMembers] = useState([]);
    const [owners, setOwners] = useState([]);
    const [currentMember, setCurrentMember] = useState("");
    const [currentOwner, setCurrentOwner] = useState(currentUser);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [currentOwnerIndex, setCurrentOwnerIndex] = useState(-1);
    const [descLength, setDescLength] = useState(false);
    const [noDueDate, setNoDueDate] = useState(true);

    const [showChangeOwner, setShowChangeOwner] = useState(false);
    const handleChangeOwnerOpen = () => setShowChangeOwner(true);
    const handleChangeOwnerClose = () => setShowChangeOwner(false);

    useEffect(() => {
        retrieveMembers();
        if (currentUser.familyId === null) {
            navigate("/needfamily")
        }
    }, []);

    useEffect(() => {
        handleDescriptionLength();
    })

    const setBox = () => {
        if (noDueDate === true) {
            let noDueDate = false;
            setNoDueDate(noDueDate);
            setTask({ ...task, dueDate: new Date() });
        } else {
            let noDueDate = true;
            setNoDueDate(noDueDate);
            setTask({ ...task, dueDate: null });
        }
    };

    const retrieveMembers = () => {
        FamilyService.listMembers(currentUser.userId)
            .then(response => {
                setMembers(response.data);
                setOwners(response.data);
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const setActiveMember = (member, index) => {
        setCurrentMember(member);
        setCurrentIndex(index);
    };

    const setActiveOwner = (owner, ownerIndex) => {
        setCurrentOwner(owner);
        setCurrentOwnerIndex(ownerIndex);
        handleChangeOwnerClose();
    };

    const handleInputChange = event => {
        const { name, value } = event.target;
        setTask({ ...task, [name]: value });
    };

    const handleDescriptionLength = () => {
        if (task.description.length > 254) {
            setDescLength(false);
        } else {
            setDescLength(true);
        };
    };

    const addTask = (e) => {
        e.preventDefault();

        setMessage("");
        setSuccessful(false);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            var data = {
                title: task.title,
                familyId: task.familyId,
                userId: currentOwner.userId,
                ownerId: currentMember.userId,
                description: task.description,
                dueDate: task.dueDate
            };

            TaskService.create(data).then(
                (response) => {
                    navigate("/tasks");
                    setTask({
                        taskId: response.data.taskId,
                        familyId: response.data.familyId,
                        userId: currentUser.userId,
                        ownerId: response.data.ownerId,
                        title: response.data.title,
                        description: response.data.description,
                        completed: response.data.completed
                    });

                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setMessage(resMessage);
                    setSuccessful(false);
                }
            );
        }
    };

    return (
        <div className="list row">
            <div className="col-md-6">
                <FadeIn>
                    <h4>Add Task</h4>
                    <Form onSubmit={addTask} ref={form}>
                        <div>
                            <div className="form-group">
                                <label htmlFor="title" className="form-label">
                                    <strong>Title *</strong>
                                </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="title"
                                    name="title"
                                    value={task.title}
                                    onChange={handleInputChange}
                                    validations={[required, titleLength]}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="description" className="form-label">
                                    <strong>Description</strong>
                                </label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="description"
                                    rows="4"
                                    name="description"
                                    value={task.description}
                                    onChange={handleInputChange}
                                //validations={[titleLength]}
                                />
                                {task.description.length > 254 ?
                                    <div className="alert alert-danger">Description cannot exceed 255 characters!</div>
                                    :
                                    null
                                }
                            </div>
                            <ul className="list-group">
                                <label htmlFor="owner" className="form-label">
                                    <strong>Assigned To *</strong>
                                    <div>
                                        <i>Select a family member</i>
                                    </div>
                                </label>
                                {members &&
                                    members.map((member, index) => (
                                        <li
                                            className={
                                                "list-group-item " + (index === currentIndex ? "active" : "")
                                            }
                                            onClick={() => setActiveMember(member, index)}
                                            onChange={handleInputChange}
                                            key={index}
                                        >
                                            {member.mediaLink.length > 77 ?
                                                <img className="img me-2" src={member.mediaLink} alt={member.mediaLink} height="33px" />
                                                :
                                                <Avatar className="me-2" name={member.firstname + " " + member.lastname} color="gray" round={true} size="33px" textSizeRatio={1.75} />
                                            }
                                            {member.firstname} {member.lastname}
                                        </li>
                                    ))}
                            </ul>
                            <p />
                            <div className="form-group">
                                <strong>Task Owner</strong>
                                <div className="row">
                                    <div className="col-1">
                                        {currentOwner.userMediaLink ?
                                            <Avatar name={(task.firstname != null ? task.firstname + " " + task.lastname : "?")} color="gray" round={true} size="25" textSizeRatio={1.75} />
                                            :
                                            <img className="img" src={currentOwner.mediaLink} alt={currentOwner.mediaLink} height="25px" />
                                        }
                                    </div>
                                    <div className="col-5" style={{
                                        "font-size": '14px'
                                    }}>
                                        {task.userId != null ?
                                            <div className="mt-1">{currentOwner.firstname} {currentOwner.lastname}</div>
                                            :
                                            <div className="mt-1 ms-2" style={{
                                                "color": 'red'
                                            }}>Nobody!</div>
                                        }
                                    </div>
                                    <div className="col">
                                        <Button variant="primary" onClick={handleChangeOwnerOpen}>
                                            Change Task Owner
                                        </Button>
                                    </div>
                                </div>
                                <Modal centered show={showChangeOwner} onHide={handleChangeOwnerClose}>
                                    <Modal.Body closeButton>
                                        <div>
                                            <strong>Change Task Owner?</strong>
                                        </div>
                                        <i>The owner will get updates and notifications. Who should know when you complete the task?</i>
                                        <ul className="list-group">
                                            <label htmlFor="owner" className="form-label">
                                                <div>

                                                </div>
                                            </label>
                                            {owners &&
                                                owners.map((owner, ownerIndex) => (
                                                    <li
                                                        className={
                                                            "list-group-item " + (ownerIndex === currentOwnerIndex ? "active" : "")
                                                        }
                                                        onClick={() => setActiveOwner(owner, ownerIndex)}
                                                        key={ownerIndex}
                                                    >
                                                        {owner.mediaLink.length > 77 ?
                                                            <img className="img me-2" src={owner.mediaLink} alt={owner.mediaLink} height="33px" />
                                                            :
                                                            <Avatar className="me-2" name={owner.firstname + " " + owner.lastname} color="gray" round={true} size="33px" textSizeRatio={1.75} />
                                                        }
                                                        {owner.firstname} {owner.lastname}
                                                    </li>
                                                ))}
                                        </ul>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleChangeOwnerClose}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                <div class="form-check">
                                    {noDueDate === true ?
                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                                            onClick={() => setBox()} checked
                                        />
                                        :
                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                                            onClick={() => setBox()}
                                        />
                                    }
                                    No Due Date.
                                </div>
                                <div className="form-label">
                                    <strong>Due Date *</strong>
                                </div>
                                {noDueDate === true ?
                                    <DatePicker
                                        selected={task.dueDate}
                                        onChange={date => handleInputChange({
                                            target: {
                                                value: date,
                                                name: 'dueDate'
                                            }
                                        })}
                                        name="dueDate"
                                        dateFormat="yyyy-MM-dd"
                                        popperPlacement="top-start"
                                        disabled
                                    />
                                    :
                                    <DatePicker
                                        selected={task.dueDate}
                                        onChange={date => handleInputChange({
                                            target: {
                                                value: date,
                                                name: 'dueDate'
                                            }
                                        })}
                                        name="dueDate"
                                        dateFormat="yyyy-MM-dd"
                                        popperPlacement="top-start"
                                    />
                                }
                                <p />
                                <p></p>
                            </div>
                            <div className="form-group">
                                {currentMember ?
                                    <div>
                                        <button
                                            className="btn btn-success mb-5"
                                            style={{
                                                "--bs-btn-line-height": "1.3",
                                                "--bs-btn-border-radius": "4rem",
                                                "--bs-btn-padding-x": "0.95rem"
                                            }}
                                        >
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                </svg>
                                                <div style={{
                                                    "fontSize": "10px"
                                                }}>
                                                    <strong>Submit</strong>
                                                </div>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-secondary ms-2 mb-5"
                                            style={{
                                                "--bs-btn-line-height": "1.3",
                                                "--bs-btn-border-radius": "4rem",
                                                "--bs-btn-padding-x": "0.95rem"
                                            }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-backspace" viewBox="0 0 16 16">
                                                <path d="M5.83 5.146a.5.5 0 0 0 0 .708L7.975 8l-2.147 2.146a.5.5 0 0 0 .707.708l2.147-2.147 2.146 2.147a.5.5 0 0 0 .707-.708L9.39 8l2.146-2.146a.5.5 0 0 0-.707-.708L8.683 7.293 6.536 5.146a.5.5 0 0 0-.707 0z" />
                                                <path d="M13.683 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-7.08a2 2 0 0 1-1.519-.698L.241 8.65a1 1 0 0 1 0-1.302L5.084 1.7A2 2 0 0 1 6.603 1h7.08zm-7.08 1a1 1 0 0 0-.76.35L1 8l4.844 5.65a1 1 0 0 0 .759.35h7.08a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-7.08z" />
                                            </svg>
                                            <div style={{
                                                "fontSize": "10px"
                                            }}>
                                                <Link to={"/tasks"} className="nav-link">
                                                    <strong>Cancel</strong>
                                                </Link>
                                            </div>
                                        </button>
                                    </div>
                                    :
                                    <div>
                                        <button
                                            className="btn btn-success disabled mb-5"
                                            style={{
                                                "--bs-btn-line-height": "1.3",
                                                "--bs-btn-border-radius": "4rem",
                                                "--bs-btn-padding-x": "0.95rem"
                                            }}
                                        >
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                </svg>
                                                <div style={{
                                                    "fontSize": "10px"
                                                }}>
                                                    <strong>Submit</strong>
                                                </div>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-secondary ms-2 mb-5"
                                            style={{
                                                "--bs-btn-line-height": "1.3",
                                                "--bs-btn-border-radius": "4rem",
                                                "--bs-btn-padding-x": "0.95rem"
                                            }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-backspace" viewBox="0 0 16 16">
                                                <path d="M5.83 5.146a.5.5 0 0 0 0 .708L7.975 8l-2.147 2.146a.5.5 0 0 0 .707.708l2.147-2.147 2.146 2.147a.5.5 0 0 0 .707-.708L9.39 8l2.146-2.146a.5.5 0 0 0-.707-.708L8.683 7.293 6.536 5.146a.5.5 0 0 0-.707 0z" />
                                                <path d="M13.683 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-7.08a2 2 0 0 1-1.519-.698L.241 8.65a1 1 0 0 1 0-1.302L5.084 1.7A2 2 0 0 1 6.603 1h7.08zm-7.08 1a1 1 0 0 0-.76.35L1 8l4.844 5.65a1 1 0 0 0 .759.35h7.08a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-7.08z" />
                                            </svg>
                                            <div style={{
                                                "fontSize": "10px"
                                            }}>
                                                <Link to={"/tasks"} className="nav-link">
                                                    <strong>Cancel</strong>
                                                </Link>
                                            </div>
                                        </button>
                                    </div>
                                }

                            </div>
                        </div>
                        <p />
                        {message && (
                            <div className="form-group">
                                <div
                                    className={
                                        successful ? "alert alert-success" : "alert alert-danger"
                                    }
                                    role="alert"
                                >
                                    {message}
                                </div>
                            </div>
                        )}
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                </FadeIn>
            </div>
        </div>

    );
};

export default Register;